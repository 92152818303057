import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook
import {
  request,
  setAuthHeader,
  axiosInstance1,
} from "../../config/axiosConfig";
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Link,
} from "@mui/material";
import NewNavBar from "../../components/appBar/NewNavBar";

const LoginPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [formData, setFormData] = useState({
    login: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [technician, setTechnician] = useState([]);
  const [userId, setUserId] = useState(null);
const [roleId, setRoleId] = useState(null);

  const onChangeHandler = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setError("");
  };

  useEffect(() => {
  const fetchTechnician = async () => {
    if (roleId === 3 && userId) {
      try {
        const response = await request(
          axiosInstance1,
          "GET",
          `/getTechnicianDetailsByUserId/${userId}`
        );
        setTechnician(response.data);
        console.log("Technician:", response.data);

        if (response.data.status !== "Active") {
          setError("Technician not approved");
          localStorage.clear();
        } else {
          navigate("/portal/technicianDashboard");
        }
      } catch (error) {
        console.error("Error fetching technician:", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  fetchTechnician();
}, [ roleId, userId]);

  const onSubmitLogin = async (e) => {
    e.preventDefault();
    // Clear localStorage
    localStorage.clear();
    const { login, password } = formData;

    setIsSubmitting(true);

    try {
      console.log("hi");
      const response = await request(axiosInstance1, "POST", "/login", {
        login,
        password,
      });

      console.log(response.data);

      if (!response.data) {
        throw new Error("Invalid response data");
      }

      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("userid", response.data.id);
      localStorage.setItem("firstName", response.data.firstName);
      localStorage.setItem("lastName", response.data.lastName);
      localStorage.setItem("companyId", response.data.companyId);
      setAuthHeader(response.data.token);

      setUserId(response.data.id); 
      setRoleId(response.data.roleId);

    } catch (error) {
      console.error("Login error:", error);
      if (error.response) {
        setError(error.response.data.message);
        console.error("Error message:", error.response.data.message);
        console.error("Status code:", error.response.status);
        console.error("Headers:", error.response.headers);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  


  const handleClick = (type) => {
    localStorage.setItem("userType", type);
    navigate("/forgotPassword");
  };

  return (
    <>
      <div
        style={{
          background: "linear-gradient(to right, #FCCF00,#002252, #002252)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "160px",
            left: "-125px",
            width: "250px",
            height: "250px",
            background: "rgba(255, 255, 255, 0.2)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "-250px",
            left: "20px",
            width: "600px",
            height: "300px",
            background: "rgba(255, 255, 255, 0.1)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "100px",
            right: "-200px",
            width: "300px",
            height: "400px",
            background: "rgba(255, 255, 255, 0.1)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <NewNavBar />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80%",
          }}
        >
          <Container component="main" maxWidth="xs">
            <Box
              sx={{
                marginTop: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: 2,
                borderRadius: 5,
                padding: 4,
                backgroundColor: "white",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontWeight: "bold",
                  mt: 3,
                }}
              >
                <span
                  style={{
                    borderBottom: "3px solid #002252",
                    mr: "-4px",
                    pr: "4px",
                    color: "#FCCF00",
                  }}
                >
                  Technician {"\u00A0"}
                </span>
                <span
                  style={{
                    borderBottom: "3px solid #FCCF00",
                    color: "#002252",
                    ml: "-4px",
                    pl: "4px",
                  }}
                >
                  Login
                </span>
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={onSubmitLogin} // Change the onSubmit function
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      required
                      fullWidth
                      id="loginName"
                      name="login"
                      label="Username"
                      autoComplete="family-name"
                      onChange={onChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      size="small"
                      type="password"
                      required
                      fullWidth
                      id="loginPassword"
                      name="password"
                      label="Password"
                      autoComplete="family-name"
                      onChange={onChangeHandler}
                    />
                  </Grid>
                  {error && (
                    <Grid item xs={12}>
                      <Typography color="error" variant="body2">
                        {error}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={6} sm={6}>
                    {/* <FormControlLabel
                      control={<Checkbox value="remember" color="primary"  />}
                      label={
                        <span style={{ fontSize: "15px", color:"black" }}>Remember me</span>
                      }
                    /> */}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    sx={{ textAlign: "right", marginTop: 0.5 }}
                  >
                    <Link
                      onClick={() => handleClick("technician")}
                      variant="body2"
                      sx={{
                        color: "#002252",
                        fontWeight: "bold",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      Forgot Password ?
                    </Link>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    borderRadius: 3,
                    backgroundColor: "#002252", // Set background color to blue
                    color: "#FFFFFF", // Set text color to white
                    "&:hover": {
                      backgroundColor: "#001d40", // Optional: Darker blue on hover
                    },
                    textTransform: "none",
                  }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Please wait..." : "LOGIN"}
                </Button>
                <Grid container justifyContent="center">
                  <Grid item>
                    <span style={{ fontSize: "15px", color: "black" }}>
                      Not registered?{" "}
                    </span>
                    <Link
                      href="#/technicianReg"
                      variant="body2"
                      sx={{ color: "#002252", textDecoration: "none" }}
                    >
                      Register
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
