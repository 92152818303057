import React, { useState } from "react";
import PageHeader from "../../components/header/PageHeader";
import NavBar from "../../components/appBar/AppBar";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { BsFillTelephoneFill } from "react-icons/bs";
import Footer from "../../components/footer/Footer";
import NewNavBar from "../../components/appBar/NewNavBar";

function ElectricalServices() {
  const [items, setItems] = useState([
    "Qualified Inspection: Conducted by a fully qualified A Grade Electrician to ensure expert assessment.",
    "Main System Testing: Testing of consumer mains and the main earthing system to guarantee electrical stability.",
    "Switchboard Testing: Comprehensive testing of the electrical switchboard to verify proper functionality and compliance.",
    "RCD Testing: Testing of Residual-Current Devices (RCDs) to ensure they effectively protect against electrical hazards.",
    "Fixture and Appliance Inspection: Inspection and testing of all electrical fixtures, fittings, and landlord-provided appliances for safety and compliance.",
    "Detailed Safety Report: A safety report issued within 24 hours, detailing test results and any compliance issues.",
    "Record Keeping & Scheduling: Free record-keeping and scheduling services to simplify ongoing compliance management.",
    "Free Rectification Quotes: Free quotes for any necessary rectification work to meet compliance standards.",
    "Thorough Examination: Comprehensive inspection of switchboards, RCDs, and all electrical installations to confirm their safety."
  ]);
  return (
    <div>
      <NewNavBar activePage="Our services" />
      {/* Heading */}
      <div className="relative w-screen bg-black h-100px">
        <div className='w-full h-[250px] lg:h-[350px] sm:h-[400px] bg-gradient-to-r from-[#FCD419] via-[#FCD419] to-[#ffffff]'></div>
        {/* <img src="aboutImage.jpg" alt="" className="object-cover w-full h-[250px] lg:h-[450px] sm:h-[400px]" /> */}
        <div className='absolute inset-0 flex items-center justify-center mt-3'>
          <div className="w-[1061px] h-full flex items-center justify-center">
            <div className='relative top-0 left-0 justify-start md:w-[200px] sm:w-[250px] lg:w-[1061px] h-full md:text-xl font-bold text-black'>
              <div className='border-r-2 border-b-2 sm:w-[200px] lg:w-[200px] sm:mt-16 sm:ml-10 border-black pr-1'>OUR SERVICES</div>
            </div>
          </div>
        </div>
        <div className='absolute inset-0 flex flex-col items-center justify-center text-3xl font-black text-center text-black sm:text-5xl lg:text-5xl'>
          <div className=''>Electrical Services</div>
        </div>
      </div>

      {/* Service description */}
      <div className="px-10 pt-20">
        <div className="font-bold text-[#0b2032] text-2xl">Compliance Made Simple: Safeguard Your Property with Regular Safety Checks</div>
        <div className="pt-2">
          As a landlord or property manager, ensuring the safety of your tenants and maintaining legal compliance is essential. New Victorian regulations mandate regular safety checks for gas, electrical, and fire systems in rental properties, making it crucial to stay ahead of these requirements.

        </div>
      </div>

      {/* Description */}
      <div className="flex flex-col px-10 mt-10 md:mt-20">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="flex flex-col w-full ">
            <div className="w-full h-[450px]">
              <img
                src="ElectricalPic1.svg"
                alt="Electrical Service Description img1"
                className="w-[500px] h-[500px]"
              />
            </div>
            <div className="flex flex-col h-full mb-3 ">
              {/* <div className="text-lg font-bold md:text-xl md:mr-10 md:mt-16 sm:mt-16 sm:text-xl">
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.{" "}
              </div> */}
              {/* <div className="pt-32 mt-2 text-base sm:text-lg md:mr-10">
                At Promender, we make compliance easy and stress-free with our complete range of services. From electrical and smoke alarm checks to gas safety inspections, we offer thorough, friendly, and accredited services tailored to your property's needs. Our certified professionals use advanced technology to streamline scheduling, inspections, and reporting, helping you stay compliant effortlessly.{" "}
              </div> */}
            </div>
          </div>
          <div className="flex flex-col md:w-full ">
            {/* <div className="w-full md:hidden">
              <img
                src="ElectricalPic2.svg"
                alt="Electrical Service Description img2"
                className="w-[500px] h-[500px]"
              />
            </div> */}
            <div className="flex flex-col h-full gap-10 md:mb-3">
              <div className="text-sm md:mt-4 md:ml-10 sm:text-lg md:text-base">
                At Promender, we make compliance easy and stress-free with our complete range of services. From electrical and smoke alarm checks to gas safety inspections, we offer thorough, friendly, and accredited services tailored to your property's needs. Our certified professionals use advanced technology to streamline scheduling, inspections, and reporting, helping you stay compliant effortlessly.{" "}
              </div>
              <div className="mt-2 text-sm md:ml-10 sm:text-lg md:text-base">
                With us, you’ll receive full documentation after each check, accessible through our secure online portal, and we’ll even assist in arranging any necessary repairs through our trusted network of tradespeople. Prioritize safety, avoid costly accidents, and protect your investment with routine compliance checks—because peace of mind starts with proper safety measures.
                Trust us to keep your property safe, compliant, and worry-free, while you focus on managing your responsibilities effectively.{" "}
              </div>
            </div>
            {/* <div className="w-full h-[550px] hidden md:flex">
              <img
                src="ElectricalPic2.svg"
                alt="Electrical Service Description img2"
                className="w-[500px] h-[500px]"
              />
            </div> */}
          </div>
        </div>
      </div>

     

      {/* Horizontal Line 2 */}
      <div className="w-full h-[227px] bg-[#FCCF00]  hidden mb-20 lg:flex items-center mt-20">
        <div className="grid w-full grid-cols-12">
          {/* Find A Technician Today! */}
          <div className="flex items-center col-span-9 sm:col-span-6">
            <div className="mx-auto text-4xl font-bold text-white">
              Find A Technician Today!
            </div>
          </div>
          {/* Find technician button */}
          <div className="flex justify-end col-span-3 sm:col-span-4">
            <div className="w-[300px]">
              <button className="w-full py-3 text-lg font-bold text-white bg-[#002252]">
                FIND A TECHNICIAN
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*mobile*/}
      <div className="lg:hidden sm:mt-16 w-full flex flex-col h-[180px] bg-[#FCCF00] items-center justify-center gap-4">
        <div className="text-2xl font-bold text-white sm:text-4xl">Find A Technician</div>
        <button className="bg-[#002252] text-white px-4 py-2 rounded-md sm:text-lg font-bold">Find a Technician</button>
      </div>

      {/* Unorderd List */}
      <div className="flex flex-col w-full mb-3 bg-gray-100 2xl:min-h-screen">
        <main className="flex-grow px-6 py-6">
          <div className="flex flex-col space-y-4">
            <div className="px-2 py-4 2xl:px-6 ">
              <h2 className="text-2xl 2xl:text-2xl">
                Electrical Safety Check
              </h2>
              <p className="mt-2 2xl:mt-5 2xl:text-lg sm:text-lg">
                Our Electrical Safety Checks are designed to ensure that all electrical systems and appliances in your property are safe, compliant, and functioning properly. This service includes:
              </p>
              <ul className="mt-4 2xl:mt-5">
                {items.map((item, index) => (
                  <li key={index} className="flex mt-3">
                    <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
                    <span className="2xl:text-lg sm:text-lg">{item}</span>
                  </li>
                ))}
              </ul>
              <p class="2xl:text-lg 2xl:pt-5 sm:text-lg">
                These additional benefits ensure that your electrical systems are safe, compliant, and fully functional, providing peace of mind for you as a landlord or property manager while safeguarding your property and tenants.
              </p>
              <div className="flex flex-col items-center pt-4 2xl:flex-row">
                {" "}
                <div className="2xl:w-[300px] w-[200px]">
                  <button className="w-full py-3 2xl:text-lg font-bold text-white ml-3 sm:text-lg bg-gradient-to-tr from-[#FCCF00] to-[#002252] rounded-2xl">
                    ENQUIRE NOW
                  </button>
                </div>
                <div className="flex flex-col justify-center 2xl:ml-20">
                  <div className="flex flex-row pt-2">
                    <div className="flex flex-col justify-center pr-3">
                      <BsFillTelephoneFill className="2xl:w-12 w-10 h-10 2xl:h-12 text-[#002252]" />
                    </div>
                    <div className="flex flex-col">
                      <div className="2xl:text-lg sm:text-lg font-bold text-[#002252]">
                        Call us:
                      </div>
                      <div className="2xl:text-3xl text-2xl font-bold text-[#002252] sm:text-3xl">
                        +61 400 673 184
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>


      {/* Smoke alarm service */}
      <div className="flex flex-col px-16 pt-20">
        <div className="text-3xl font-bold text-[#002252]">
          Smoke Testing
        </div>
        <div className="pt-3 text-lg font-medium">
          Ensuring the proper functionality of smoke alarms is essential to maintaining a safe living environment. Our Smoke Alarm Checks include:
        </div>
        <div className="pt-4">
          <div className="flex flex-row gap-2">
            <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
            <div>Comprehensive Testing: We conduct thorough testing to assess the volume, positioning, and any potential damage to your smoke alarms.</div>
          </div>

          <div className="flex flex-row gap-2 pt-3">
            <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
            <div>Sound Pressure Level Recording: We record sound pressure levels to ensure that alarms meet the required volume standards for effective notification.</div>
          </div>

          <div className="flex flex-row gap-2 pt-3">
            <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
            <div>Battery Replacement: We replace batteries in all smoke alarms where necessary, ensuring continuous functionality.</div>
          </div>

          <div className="flex flex-row gap-2 pt-3">
            <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
            <div>Installation Verification: We verify that each smoke detector is properly installed and fully functional.</div>
          </div>

          <div className="flex flex-row gap-2 pt-3">
            <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
            <div>Alarm Sound Check: We confirm that all fire alarms emit sounds at appropriate volume levels to ensure tenant safety.</div>
          </div>
          <div className="flex flex-row gap-2 pt-3">
            <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
            <div>Detailed Audit Report: After the inspection, we provide a comprehensive audit report, including a compliance certificate and a summary of all work completed.</div>
          </div>
          <div className="flex flex-row gap-2 pt-3">
            <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
            <div>Free Rectification Quotes: We offer free quotes for any rectification work required to bring your smoke alarms into compliance with safety regulations.</div>
          </div>
        </div>

        <div className="pt-5 text-lg font-normal">
          Additional Benefits
        </div>

        <div className="pt-4">
          <div className="flex flex-row gap-2">
            <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
            <div>Long-Term Compliance: We provide free record-keeping and reminders for future inspections, ensuring your property remains compliant year after year.</div>
          </div>

          <div className="flex flex-row gap-2 pt-3">
            <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
            <div>Hassle-Free Maintenance: With our free battery replacements during checks, you avoid the inconvenience of needing to schedule extra maintenance visits.</div>
          </div>

          <div className="flex flex-row gap-2 pt-3">
            <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
            <div>Expert Guidance: Our certified team is available to help you understand inspection results and assist with any necessary compliance steps.</div>
          </div>

          <div className="flex flex-row gap-2 pt-3">
            <BsFillCheckCircleFill className="mr-2 2xl:mr-5 text-[#FCCF00] text-2xl" />
            <div>Affordable Solutions: Enjoy our free quotes for any required repairs or upgrades, ensuring you address any safety issues cost-effectively.</div>
          </div>
        </div>

        <div className="pt-5 text-lg font-normal">
          These additional benefits ensure that your property is equipped with fully operational smoke alarms, enhancing tenant safety and ensuring peace of mind for landlords and property managers alike.
        </div>
      </div>

      {/* Icon List */}
      <div className="mt-24 ml-10 font-bold sm:text-xl">
        The types of electrical service ProMender provide include:
      </div>
      <div className="flex flex-col items-center gap-5 mt-10 ml-10 mr-10 lg:hidden 2xl:grid md:grid-cols-6">
        {" "}
        <div className="flex items-center justify-center w-[200px] h-[200px] bg-white border border-black md:aspect-square rounded-3xl">
          <img src="ES_V1.png" alt="gas services icon 1" />
        </div>
        <div className="flex items-center justify-center bg-white border w-[200px] h-[200px] border-black md:aspect-square rounded-3xl">
          <img src="ES_V2.png" alt="gas services icon 2" />
        </div>
        <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
          <img src="ES_V3.png" alt="gas services icon 3" />
        </div>
        <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
          <img src="ES_V2.png" alt="gas services icon 4" />
        </div>
        <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
          <img src="ES_V1.png" alt="gas services icon 5" />
        </div>
        <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
          <img src="ES_V4.png" alt="gas services icon 6" />
        </div>
      </div>

      {/*Mobile*/}
      <div className="flex-col items-center hidden w-full mt-10 lg:flex xl:hidden bg-blue-gray-900">
        <div className="flex flex-row justify-between w-3/4">
          <div className="flex items-center justify-center w-[200px] h-[200px] bg-white border border-black md:aspect-square rounded-3xl">
            <img src="ES_V1.png" alt="gas services icon 1" />
          </div>
          <div className="flex items-center justify-center bg-white border w-[200px] h-[200px] border-black md:aspect-square rounded-3xl">
            <img src="ES_V2.png" alt="gas services icon 2" />
          </div>
        </div>

        <div className="flex flex-row justify-between w-3/4 mt-4">
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V3.png" alt="gas services icon 3" />
          </div>
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V2.png" alt="gas services icon 4" />
          </div>
        </div>

        <div className="flex flex-row justify-between w-3/4 mt-4">
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V1.png" alt="gas services icon 5" />
          </div>
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V4.png" alt="gas services icon 6" />
          </div>
        </div>
      </div>

      {/*large screens*/}
      <div className="xl:flex flex-col items-center h-[500px] hidden 2xl:hidden mt-16">
        <div className="flex flex-row justify-between w-3/4">
          <div className="flex items-center justify-center w-[200px] h-[200px] bg-white border border-black md:aspect-square rounded-3xl">
            <img src="ES_V1.png" alt="gas services icon 1" />
          </div>
          <div className="flex items-center justify-center bg-white border w-[200px] h-[200px] border-black md:aspect-square rounded-3xl">
            <img src="ES_V2.png" alt="gas services icon 2" />
          </div>
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V3.png" alt="gas services icon 3" />
          </div>
        </div>

        <div className="flex flex-row justify-between w-3/4 mt-16">
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V2.png" alt="gas services icon 4" />
          </div>
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V1.png" alt="gas services icon 5" />
          </div>
          <div className="flex items-center justify-center bg-white border border-black w-[200px] h-[200px] md:aspect-square rounded-3xl">
            <img src="ES_V4.png" alt="gas services icon 6" />
          </div>
        </div>
      </div>


      {/* Horizontal Line 2 */}
      <div className="hidden w-full h-[227px] bg-[#002252] mt-20 mb-20 px-20 md:flex items-center">
        <div className="grid w-full md:grid-cols-12">
          {/* Find A Service Today! */}
          <div className="flex items-center c md:col-span-9 sm:col-span-6">
            <div className="mx-auto font-bold text-white md:text-4xl">
              Find A Service Today!
            </div>
          </div>

          {/* Find technician button */}
          <div className="flex justify-end md:col-span-3 sm:col-span-4">
            <div className="w-[300px]">
              <button className="w-full py-3 text-lg font-bold text-[#002252] bg-white">
                FIND A SERVICE
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Mobile*/}
      <div className="mt-4 md:hidden w-full h-[200px] bg-[#002252] flex flex-col">
        <div className="flex flex-col items-center justify-center h-full text-xl font-bold text-white sm:text-2xl">Find A Service Today!</div>
        <div className="flex flex-col items-center h-full">
          <button className="text-xl font-bold text-[#002252] px-2 py-2 rounded-md w-2/4 bg-white ">Find A Service</button>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ElectricalServices;
