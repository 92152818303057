import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  request,
  setAuthHeader,
  getAuthToken,
  axiosInstance1,
  axiosInstance2,
} from "../../config/axiosConfig";
import PageHeader from "../../components/header/PageHeader";
import NavBar from "../../components/appBar/AppBar";
import {
  Grid,
  Box,
  InputLabel,
  Container,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import StateSelect from "../../components/stateSelect/StateSelect";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import NewNavBar from "../../components/appBar/NewNavBar";

const RegWithInquiry = () => {
  const location = useLocation();
  const { selectedItems = [], totalPrice = 0 } = location.state || {};
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    username: "",
    contactNumber: "",
    address: "",
    city: "",
    state: "",
    postalCode: "",
    password: "",
    type: "",
    serviceDate: "",
    serviceTime: "",
    serviceDescription: "",
    callRequest: "",
    userId: {
      id: "",
    },
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for button status
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Set formData with the first selected item as type if available
    if (selectedItems.length > 0) {
      const currentDate = dayjs().format("MM/DD/YYYY");

      setFormData((prevFormData) => ({
        ...prevFormData,
        type: selectedItems,
        serviceDate: currentDate,
      }));
    }
  }, [selectedItems]);

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    let errorMessage = "";
    if (password.length < minLength) {
      errorMessage += `Password must be at least ${minLength} characters long. `;
    }
    if (!hasUpperCase) {
      errorMessage += "Password must contain at least one uppercase letter. ";
    }
    if (!hasLowerCase) {
      errorMessage += "Password must contain at least one lowercase letter. ";
    }
    if (!hasNumber) {
      errorMessage += "Password must contain at least one digit. ";
    }
    if (!hasSpecialChar) {
      errorMessage += "Password must contain at least one special character. ";
    }

    return errorMessage;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === "password") {
      const passwordValidationError = validatePassword(value);
      setPasswordError(passwordValidationError);
    }

    if (name === "confirmPassword") {
      if (value !== formData.password) {
        setConfirmPasswordError("Passwords do not match.");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const handleDatePickerChange = (date) => {
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setFormData({ ...formData, serviceDate: formattedDate });
  };

  const handleChangeEmail = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));

    if (name === "emailAddress") {
      setEmailError(validateEmail(value) ? "" : "Please enter a valid email address.");
    }

    if (name === "password") {
      const passwordValidationError = validatePassword(value);
      setPasswordError(passwordValidationError);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleTimePickerChange = (time) => {
    const formattedTime = time ? time.format("hh:mm A") : "";
    setFormData({ ...formData, serviceTime: formattedTime });
  };

  const handleTypeChange = (event, value) => {
    setFormData({ ...formData, type: value });
  };

  const handleTimeChange = (event, value) => {
    setFormData({ ...formData, serviceTime: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      !formData.firstName ||
      !formData.lastName ||
      !formData.emailAddress ||
      !formData.username ||
      !formData.contactNumber ||
      !formData.address ||
      !formData.city ||
      !formData.state ||
      !formData.postalCode ||
      !formData.password ||
      !formData.confirmPassword
    ) {
      setErrorMessage("Please fill in all fields.");
      return;
    }

    if (passwordError || confirmPasswordError) {
      setErrorMessage(passwordError || confirmPasswordError);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    setIsSubmitting(true); // Disable button and change text

    try {
      setAuthHeader(getAuthToken());

      // First request to save customer
      const response = await request(
        axiosInstance1,
        "POST",
        "/saveCustomer",
        formData
      );
      console.log("Customer saved: ", response.data);
      console.log("Customer sent data: ", formData);

      // Prepare data for the second request
      const updatedFormData = { ...formData, userId: { id: response.data.id } };

      // Second request to save customer inquiry
      const response2 = await request(
        axiosInstance2,
        "POST",
        "/saveCustomerInquiry",
        updatedFormData
      );

      console.log("Customer inquiry saved: ", response2.data);
      console.log("Customer inquiry data: ", updatedFormData);

      // Show success message with SweetAlert2 and redirect
      const MySwal = withReactContent(Swal);
      MySwal.fire({
        title: "Registration Successful!",
        text: "You have been successfully registered.",
        icon: "success",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/"); // Redirect to the index page
      });

      handleClearForm(); // Clear form immediately after both requests complete
    } catch (error) {
      setAuthHeader(null);
      setSuccessMessage("");
      // Check if the error message is "Login already exists"
      if (error.response && error.response.data === "Login already exists") {
        setErrorMessage("Username already exists. Please choose a different username.");
    } else {
        setErrorMessage("An error occurred. Please try again.");
    }
      console.log(error);
    } finally {
      setIsSubmitting(false); // Re-enable button and reset text
    }
  };



  const handleClearForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      emailAddress: "",
      username: "",
      contactNumber: "",
      address: "",
      city: "",
      state: "",
      postalCode: "",
      password: "",
      confirmPassword: "",
      type: "",
      serviceDate: "",
      serviceTime: "",
      serviceDescription: "",
      userId: {
        id: "",
      },
    });
    setSuccessMessage("");
    setErrorMessage("");
    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");
  };


 //address lookup

  // const [suggestedAddresses, setSuggestedAddresses] = useState([]);
  // const [addressValidationMessage, setAddressValidationMessage] = useState('');
  // const [isAddressValid, setIsAddressValid] = useState(true);

  // const handleChangeadd = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value,
  //   });

  //   if (name === 'address') {
  //     checkBusinessAddress(value);
  //   }
  // };

  // const checkBusinessAddress = (query) => {
  //   const key = 'LX3BYJ7TMW6KRDFHCPE8'; //replace with actual API key

  //   if (!query || query.trim() === '') {
  //     setIsAddressValid(false);
  //     setAddressValidationMessage('Please enter a business address.');
  //     setSuggestedAddresses([]);
  //     return;
  //   }

  //   const apiUrl = `https://api.addressfinder.io/api/au/address/autocomplete/?key=${key}&q=${encodeURIComponent(
  //     query
  //   )}&format=json&source=gnaf%2Cpaf`;

  //   axios
  //     .get(apiUrl)
  //     .then((response) => {
  //       if (response.data && response.data.completions) {
  //         setSuggestedAddresses(response.data.completions);
  //         setAddressValidationMessage('');
  //       }
  //     })
  //     .catch((error) => {
  //       setIsAddressValid(false);
  //       setAddressValidationMessage('Error occurred. Try again.');
  //       console.error('Error fetching address suggestions:', error);
  //     });
  // };

  // const selectAddress = (address) => {
  //   setFormData({
  //     ...formData,
  //     address: address.full_address,
  //   });
  //   setSuggestedAddresses([]);
  // };

  // Define the styles in JS objects
  const dropdownStyle = {
    position: 'absolute',
    backgroundColor: 'white',
    border: '1px solid #ccc',
    maxHeight: '150px',
    overflowY: 'auto',
    zIndex: 1000,
  };

  const listItemStyle = {
    cursor: 'pointer',
    padding: '5px',
    backgroundColor: '#f1f1f1',
  };

  const listItemHoverStyle = {
    backgroundColor: '#f0f0f0',
  };


  return (
    <>
      <NewNavBar />
      <Container component="main">
        <Paper
          sx={{
            width: "90%",
            maxWidth: 800,
            padding: 3,
            margin: "auto",
            marginTop: 5,
          }}
        >
          <Box
            sx={{
              backgroundColor: "#002252",
              padding: 3,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              borderRadius: "8px",

            }}
          >
            <div
              className="text-lg font-black text-center text-white lg:text-xl"
            >
              Customer Inquiry / Registration
            </div>
            <div className="pt-4 font-semibold text-white lg:text-lg lg:font-bold">Selected Services: </div>
            {selectedItems.length > 0 && (
              <div

              className="text-base font-bold text-white"
                variant="h5"
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  marginBottom: 2,
                  margin: "auto",
                }}
              >
                {`${selectedItems.join(", ")}`}
              </div>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2} mt={1} padding={3}>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> First Name:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Last Name:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Email Address:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="emailAddress"
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={handleChangeEmail}
                    error={!!emailError}
                    helperText={emailError}
                    sx={{
                      borderColor: emailError ? "red" : "",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Contact Number:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="contactNumber"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 10,
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                  />
                </Grid>
                <Grid item xs={12}>

                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> User Name:
                  </InputLabel>

                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Password:
                  </InputLabel>
                  <TextField
                    name="password"
                    type="password"
                    fullWidth
                    value={formData.password}
                    onChange={handleChange}
                    error={!!passwordError}
                    helperText={passwordError}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Confirm Password:
                  </InputLabel>
                  <TextField
                    name="confirmPassword"
                    type="password"
                    fullWidth
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    error={!!confirmPasswordError}
                    helperText={confirmPasswordError}
                  />
                </Grid>
                
                 <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: '2vh' }}>
                    <span style={{ color: 'red' }}>*</span> Address:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    sx={{ height: 100 }} 
                    multiline
                    rows={4}
                  />
                  {/* {!isAddressValid && (
                    
                    <span style={{ color: 'red', marginTop: '10px', display: 'block' }}>
                        {addressValidationMessage}
                    </span>
                  )} */}
                  {/* {suggestedAddresses.length > 0 && (
                    <div style={dropdownStyle}>
                      <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {suggestedAddresses.map((address, index) => (
                          <li
                            key={index}
                            onClick={() => selectAddress(address)}
                            style={listItemStyle}
                            onMouseOver={(e) => (e.target.style.backgroundColor = listItemHoverStyle.backgroundColor)}
                            onMouseOut={(e) => (e.target.style.backgroundColor = listItemStyle.backgroundColor)}
                          >
                            {address.full_address}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )} */}
                </Grid>
             
               
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> City:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="city"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <StateSelect formData={formData} handleChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    <span style={{ color: "red" }}>*</span> Postal Code:
                  </InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="postalCode"
                    name="postalCode"
                    value={formData.postalCode}
                    onChange={handleChange}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 5,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>Type of Service :</InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="type"
                    name="type"
                    type="type"
                    value={formData.type}
                    onChange={handleChange}
                    disabled // disable the field since it's auto-populated from selectedItems
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>Total Price:</InputLabel>
                  <TextField
                    size="small"
                    required
                    fullWidth
                    id="servicePrice"
                    name="servicePrice"
                    value={"$" + totalPrice}
                    onChange={handleChange}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      maxLength: 5,
                    }}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>Preferred Date:</InputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      sx={{ width: "100%" }}
                      size="small"
                      value={dayjs(formData.serviceDate)}
                      onChange={handleDatePickerChange}
                      minDate={dayjs()}  // Set the minimum date to today
                      format="DD/MM/YYYY"  // Set date format to dd/mm/yyyy
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>



                <Grid item xs={12} sm={6}>
                  <InputLabel sx={{ fontSize: "2vh" }}>
                    Preferred Time:
                  </InputLabel>
                  <Select
                    fullWidth
                    displayEmpty
                    name="time"
                    type="serviceTime"
                    value={formData.serviceTime}
                    onChange={handleTimeChange}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    <MenuItem value={"Morning"}>Morning</MenuItem>
                    <MenuItem value={"Noon"}>Noon</MenuItem>
                    <MenuItem value={"Evening"}>Evening</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                mt={-8}
                padding={3}
                style={{ paddingRight: "30px" }}
              >
                <Grid item xs={12} md={10} lg={15}>
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      label="Comments"
                      multiline
                      rows={8}
                      fullWidth
                      style={{ paddingTop: "5px", marginTop: "10px" }}
                      id="serviceDescription"
                      name="serviceDescription"
                      value={formData.serviceDescription}
                      onChange={handleChange}
                    />
                  </Box>
                </Grid>
              </Grid>

              {successMessage && (
                <Typography sx={{ color: "green", textAlign: "center" }}>
                  {successMessage}
                </Typography>
              )}

              {errorMessage && (
                <Typography sx={{ color: "red", textAlign: "center" }}>
                  {errorMessage}
                </Typography>
              )}

              <Button
                className="bg-[#002252]" // Apply blue background using tailwind or utility classes
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  borderRadius: 2,
                  backgroundColor: "#002252", // Ensure background color is blue in sx
                  color: "#FFFFFF", // Set text color to white
                  "&:hover": {
                    backgroundColor: "#001d40", // Optional: Darker blue on hover
                  },
                }}
                disabled={isSubmitting} // Disable button when submitting
              >
                {isSubmitting ? "Saving, please wait..." : "Submit"} {/* Change button text */}
              </Button>


              <Button
                onClick={handleClearForm}
                fullWidth
                variant="outlined"
                sx={{
                  mt: 1,
                  mb: 2,
                  borderRadius: 2,
                  borderColor: "#FCCF00", // Set outline color to yellow
                  color: "#FCCF00", // Set text color to yellow to match the outline
                  "&:hover": {
                    borderColor: "#e6ba00", // Optional: Slightly darker yellow on hover
                    color: "#e6ba00", // Optional: Change text color on hover to match outline
                  },
                }}
              >
                Clear Form
              </Button>
            </form>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default RegWithInquiry;
