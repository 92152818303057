import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { request, axiosInstance1 } from "../../config/axiosConfig";
import { Grid, Typography } from "@mui/material";

const ResPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");

  // Extract token from URL
  const getTokenFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get("token");
  };

  useEffect(() => {
    const token = getTokenFromUrl();
    if (token) {
      setLoading(true);

      const fetchData = async () => {
        try {
          const response = await request(
            axiosInstance1,
            "GET",
            `/resetPassword?token=${token}`
          );
          console.log(response);
          setResponseMsg(response.data || "Success");
        } catch (error) {
          console.error(error);
          setResponseMsg(
            error.response?.data?.message || "Something went wrong"
          );
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, [location]);

  // Redirect after showing the message
  useEffect(() => {
    if (responseMsg) {
      const timer = setTimeout(() => {
        navigate("/"); 
      }, 3000); 

      return () => clearTimeout(timer); 
    }
  }, [responseMsg, navigate]);

  return (
    <div
      style={{
        position: "relative",
        height: "100vh",
        overflow: "hidden",
        margin: 0,
        padding: 0,
      }}
    >
      <div
        style={{
          background: "linear-gradient(to right, #FCCF00, #002252, #002252)",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          zIndex: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "160px",
            left: "-125px",
            width: "250px",
            height: "250px",
            background: "rgba(255, 255, 255, 0.2)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "-250px",
            left: "20px",
            width: "600px",
            height: "300px",
            background: "rgba(255, 255, 255, 0.1)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: "100px",
            right: "-200px",
            width: "300px",
            height: "400px",
            background: "rgba(255, 255, 255, 0.1)",
            transform: "rotate(45deg)",
            zIndex: 1,
          }}
        />
      </div>

      <div style={{ position: "relative", zIndex: 2, padding: "20px" }}>
        <Grid
          container
          spacing={8}
          justifyContent="center"
          alignItems="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item xs={10} sm={8} md={6} lg={4}>
            <div className="p-8 bg-white border border-gray-300 rounded-lg shadow-md">
              <Typography
                variant="h5"
                align="center"
                style={{
                  color: "#002252",
                  marginBottom: "16px",
                  fontWeight: "700",
                  fontSize: 24,
                }}
              >
                Reset Password
              </Typography>

              {/* Loading bar */}
              {loading && (
                <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div className="h-full bg-blue-500 animate-pulse"></div>
                </div>
              )}
              <Typography
                variant="h6"
                align="center"
                style={{
                  color: "#002252",
                  marginBottom: "16px",
                  fontWeight: "400",
                  fontSize: 15,
                }}
              >
                {/* Response message below the bar */}
                {!loading && (
                  <p className="mt-4 text-gray-700">{responseMsg}</p>
                )}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ResPassword;
