import React from 'react'
import NewNavBar from '../../components/appBar/NewNavBar'
import Footer from '../../components/footer/Footer'

function PrivacyPolicy() {
    return (
        <div>
            <NewNavBar activePage="home" />
            <div className='flex flex-col'>
                <div className='pt-20 text-3xl font-bold text-center'>Privacy & Data Security Policy</div>
                <div className=' px-[200px]'>
                    <div className='pt-10 text-lg font-bold'>Effective Date: 01/01/2025</div>
                    <div className='pt-4 text-base'>At Promender, we prioritize the privacy and security of our users’ personal information. This Privacy & Data Security Policy explains how we collect, use, disclose, and safeguard your data when you visit our website, mobile application, or use our services.
                        By accessing or using Promender’s services, you agree to the practices described in this policy. If you do not agree, please discontinue the use of our services.</div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>1. Information We Collect</div>
                    <div className='pt-2 text-base'>We collect various types of personal information to provide and improve our services. The types of data we collect include:</div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>1.1 Personal Information</div>
                    <div className='pt-2 text-base'> This includes information that can identify you as an individual, such as:</div>
                    <div className='pt-2 text-base'>
                        •	Name<br></br>
                        •	Email address<br></br>
                        •	Phone number<br></br>
                        •	Postal address<br></br>
                        •	Payment information (credit card details, bank account numbers, etc.)<br></br>
                        •	Login credentials (username and password)
                    </div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>1.2 Service-Related Information</div>
                    <div className='pt-2 text-base'> This includes data related to your use of our platform, such as:</div>
                    <div className='pt-2 text-base'>
                        •	Details of the services you request or book<br></br>
                        •	Communication history with our support team<br></br>
                        •	Reviews and feedback<br></br>
                    </div>


                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>1.3 Technical Information</div>
                    <div className='pt-2 text-base'> We automatically collect certain information when you visit our website or use our mobile app, including:</div>
                    <div className='pt-2 text-base'>
                        •	IP address<br></br>
                        •	Browser type and version<br></br>
                        •	Device information (e.g., mobile device ID)<br></br>
                        •	Cookies and other tracking technologies<br></br>
                    </div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>1.4 Location Information</div>
                    <div className='pt-2 text-base'> When you use location-enabled services, we may collect your precise location using GPS technology. You may choose to disable location services on your device if you do not want us to collect this information.</div>


                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>2. How We Use Your Information</div>
                    <div className='pt-2 text-base'> We use the collected information for the following purposes:</div>
                    <div className='pt-2 text-base'>
                        •	To provide, operate, and maintain our services.<br></br>
                        •	To process and manage bookings, payments, and service requests.<br></br>
                        •	To improve and personalize your experience with Promender.<br></br>
                        •	To communicate with you about your bookings, updates, or promotional offers.<br></br>
                        •	To improve website and app functionality through analytics.<br></br>
                        •	To ensure compliance with legal obligations and protect against fraud.<br></br>
                    </div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>3. Data Sharing and Disclosure</div>
                    <div className='pt-2 text-base'>We do not sell or rent your personal data to third parties. However, we may share your information in the following instances:</div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>3.1 Service Providers</div>
                    <div className='pt-2 text-base'> We share your data with third-party service providers who help us deliver our services (e.g., payment processors ). These providers are bound by confidentiality agreements and are only allowed to use your data as necessary to perform their services.</div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>3.2 Legal Obligations</div>
                    <div className='pt-2 text-base'>We may disclose your information to comply with legal requirements, such as responding to court orders or requests from law enforcement agencies.</div>


                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>3.3 Business Transfers</div>
                    <div className='pt-2 text-base'>In the event of a merger, acquisition, or asset sale, your personal data may be transferred as part of the business transaction. We will notify you if this occurs and explain any new privacy policies that may apply.</div>


                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>4. Data Security</div>
                    <div className='pt-2 text-base'> Promender is committed to protecting your data. We implement industry-standard security measures to protect your personal information from unauthorized access, misuse, or disclosure, including:</div>
                    <div className='pt-2 text-base'>
                        •	Encryption: We use secure encryption protocols (SSL/TLS) to protect your personal and payment information during transmission.<br></br>
                        •	Data Storage: We store your data in secure data centers with restricted access.<br></br>
                        •	Access Control: Only authorized personnel have access to your data, and they are required to keep your information confidential.<br></br>
                        •	Monitoring: We regularly monitor our systems for vulnerabilities and security issues.<br></br>
                    </div>
                    <div className='pt-2 text-base'> While we take all reasonable steps to protect your data, please be aware that no method of data transmission over the internet or method of electronic storage is 100% secure.</div>


                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>5. Your Privacy Rights</div>
                    <div className='pt-2 text-base'>You have certain rights regarding your personal information. These include:</div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>5.1 Right to Access</div>
                    <div className='pt-2 text-base'>You can request access to the personal information we hold about you.</div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>5.2 Right to Rectification</div>
                    <div className='pt-2 text-base'>If your personal information is inaccurate or incomplete, you have the right to request correction or update.</div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>5.3 Right to Erasure</div>
                    <div className='pt-2 text-base'>You may request the deletion of your personal information, except when we are required to retain it for legal or operational purposes.</div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>5.4 Right to Data Portability</div>
                    <div className='pt-2 text-base'>You can request a copy of your personal data in a structured, machine-readable format.</div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>5.5 Right to Object</div>
                    <div className='pt-2 text-base'>You can object to certain processing of your personal data, including for direct marketing purposes.
                        To exercise any of these rights, please contact us at info@promender.com.au.
                    </div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>6. Data Retention</div>
                    <div className='pt-2 text-base'>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or for the period required by law. Once your data is no longer required, it will be securely deleted or anonymized.
                    </div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>7. Cookies and Tracking Technologies</div>
                    <div className='pt-2 text-base'>We use cookies and similar technologies to enhance your experience on our website and app. Cookies are small data files stored on your device that help us understand how you use our services.
                    </div>

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>7.1 Types of Cookies We Use:</div>
                    <div className='pt-2 text-base'>
                        •	Essential Cookies: Required for basic functionality, such as login and navigation.<br></br>
                        •	Analytical Cookies: Help us track website performance and user behavior.<br></br>
                        •	Marketing Cookies: Used to deliver personalized ads and promotions.<br></br>
                    </div>
                    <div className='pt-2 text-base'>You can control or disable cookies through your browser settings, but this may affect your experience on the platform.</div>


                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>8. Third-Party Links</div>
                    <div className='pt-2 text-base'>Our website and app may contain links to third-party websites. We are not responsible for the privacy practices of those websites. Please review their privacy policies before providing any personal information.
                    </div>   
                    
                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>9. Updates to this Privacy Policy</div>
                    <div className='pt-2 text-base'>We may update this Privacy & Data Security Policy from time to time. When we do, we will post the updated version on our website and notify you by email or other communication methods. Please review the updated policy carefully to understand how your data is protected.
                    </div>  

                    {/* Sub topic */}
                    <div className='pt-4 text-xl font-bold'>10. Contact Us</div>
                    <div className='pt-2 text-base'> If you have any questions, concerns, or requests regarding your privacy or this policy, please contact us:</div>
                    <div className='pt-2 text-base'>
                        •	Email	: info@promender.com.au<br></br>
                        •	Phone	: 0400 673 184<br></br>
                        •	Address: 29, Parkrise Blvd, Clyde North, VIC 3978<br></br>
                    </div>          
                </div>
            </div>

            <Footer/>
        </div>
    )
}

export default PrivacyPolicy