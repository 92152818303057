import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography,
} from "@mui/material";
import {
  request,
  setAuthHeader,
  axiosInstance1,
  getAuthToken,
} from "../../config/axiosConfig";
import Swal from "sweetalert2";
import debounce from "lodash.debounce";

export default function Customers() {
  const [customers, setCustomers] = useState([]);
  const [showDetailsPopup, setShowDetailsPopup] = useState(false);
  // const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const [customerData, setCustomerData] = useState(null); // Add state for storing customer data
  //  const userId = localStorage.getItem("userid");
  const [updatingCustomerId, setUpdatingCustomerId] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  // pagination
  const [dataLoading, setDataLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const size = 8;

  const bottomRef = useRef(null);

  useEffect(() => {
    fetchCustomers(page);
  }, [page, refreshTable]);

  const prevDataLoadingRef = useRef(false);

  useEffect(() => {
    if (!prevDataLoadingRef.current && dataLoading && bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
    prevDataLoadingRef.current = dataLoading;
  }, [dataLoading]);

  const fetchCustomers = async (pageNumber) => {
    try {
      setLoading(pageNumber === 0);
      setDataLoading(pageNumber !== 0);
      const response = await request(
        axiosInstance1,
        "GET",
        "/paginatedCustomers",
        null,
        { page: pageNumber, size: size }
      );
      const newCustomers = response.data.content;
      setHasMore(newCustomers.length > 0);
      setCustomers((prevCustomers) =>
        pageNumber === 0 ? newCustomers : [...prevCustomers, ...newCustomers]
      );
    } catch (error) {
      console.error("Error fetching customers:", error);
    } finally {
      setLoading(null); // Stop loading
      setDataLoading(null);
    }
  };

  const debouncedScrollHandler = useMemo(
    () =>
      debounce((e) => {
        const tolerance = 1;
        const bottom =
          e.target.scrollHeight - e.target.scrollTop <=
          e.target.clientHeight + tolerance;
        if (bottom && !dataLoading && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      }, 300),
    [dataLoading, hasMore]
  );

  useEffect(() => {
    return () => {
      debouncedScrollHandler.cancel();
    };
  }, [debouncedScrollHandler]);

  const setActiveStatus = async (cusId) => {
    try {
      setAuthHeader(getAuthToken());

      const res = await request(
        axiosInstance1,
        "PUT",
        `/activeCustomer/${cusId}`
      );
      setUpdatingCustomerId(null);
      setRefreshTable(!refreshTable);
    } catch (error) {
      console.log(error);
    }
  };
  const setInActiveStatus = async (cusId) => {
    try {
      setAuthHeader(getAuthToken());

      await request(axiosInstance1, "PUT", `/inactiveCustomer/${cusId}`);
      setUpdatingCustomerId(null);
      setRefreshTable(!refreshTable);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = (statusAction, customerId) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to change the status to ${
        statusAction === "activate" ? "Active" : "Inactive"
      }.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      setUpdatingCustomerId(customerId);
      if (result.isConfirmed) {
        if (statusAction === "activate") {
          setActiveStatus(customerId);
        } else {
          setInActiveStatus(customerId);
        }

        Swal.fire(
          "Status Changed!",
          `The customers's status has been changed to ${
            statusAction === "activate" ? "Active" : "Inactive"
          }.`,
          "success"
        );
      } else {
        setUpdatingCustomerId(null);
      }
    });
  };

  const openDetailsPopup = useCallback((customer) => {
    // setSelectedCustomer(customer); // Set the selected customer
    setCustomerData(customer); // Set the customer data for the popup
    setShowDetailsPopup(true);
  }, []);

  const closeDetailsPopup = useCallback(() => {
    setShowDetailsPopup(false);
  }, []);

  // const handleStatusChange = (status) => {
  //   if (selectedCustomer) {
  //     const updatedCustomers = customers.map((customer) => {
  //       if (customer.customerId === selectedCustomer.customerId) {
  //         return { ...customer, status };
  //       }
  //       return customer;
  //     });
  //     setCustomers(updatedCustomers);

  //     request(axiosInstance1, "PUT", "/updateStatus", {
  //       customerId: selectedCustomer.customerId,
  //       status,
  //     })
  //       .then((response) => {
  //         console.log("Status updated successfully:", response);
  //       })
  //       .catch((error) => {
  //         console.error("Error updating status:", error);
  //       });
  //   }
  // };

  return (
    <div>
      <div className="flex flex-col px-4 pt-3 lg:px-16 ">
        <div className="p-2 text-xl font-bold text-gray-800 sm:text-2xl lg:text-2xl">
          Customer List
        </div>
      </div>

      <div>
        {loading ? ( // Show spinner while loading
          <div className="flex items-center justify-center mt-10">
            <CircularProgress />
          </div>
        ) : (
          <Grid
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
          >
            <TableContainer
              elevation={0}
              component={Paper}
              sx={{
                marginTop: 2,
                maxHeight: "560px",
                maxWidth: "95%",
                overflowY: "scroll",
              }}
              onScroll={debouncedScrollHandler}
            >
              <Table
                sx={{
                  minWidth: 650,
                  maxWidth: "90%",
                  marginInline: 10,
                  marginTop: 1,
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    {/* <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Name
              </TableCell> */}
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      ID
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Username
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Email
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Contact Number
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Status
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: "bold" }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {customers.map((customer) => (
                    <TableRow key={customer.customerId}>
                      {/* <TableCell component="th" scope="row" align="center">
                  {customer.firstName + " " + customer.lastName}
                </TableCell> */}
                      <TableCell align="center">
                        {customer.customerId}
                      </TableCell>
                      <TableCell align="center">{customer.username}</TableCell>
                      <TableCell align="center">
                        {customer.emailAddress}
                      </TableCell>
                      <TableCell align="center">
                        {customer.contactNumber}
                      </TableCell>
                      <TableCell align="center">
                        <p
                          className={
                            customer.status === "Active"
                              ? "bg-green-400 p-1 rounded-full font-semibold m-0 align-center"
                              : customer.status === "Inactive"
                              ? "bg-red-400 p-1 rounded-full font-semibold m-0 align-center"
                              : ""
                          }
                        >
                          {customer.status}
                        </p>
                      </TableCell>
                      <TableCell align="center" className="flex flex-row">
                        <button
                          type="button"
                          className="px-3 py-2 mr-4 text-sm font-semibold text-black border-2 border-black rounded-full sm:text-xs"
                          onClick={() => openDetailsPopup(customer)}
                        >
                          View Details
                        </button>

                        {customer.status === "Pending" && (
                          <>
                            <button
                              type="button"
                              className="w-32 py-2 font-semibold text-xs text-white bg-[#008000] rounded-full"
                              onClick={() =>
                                handleStatusChange(
                                  "activate",
                                  customer.customerId
                                )
                              }
                              disabled={
                                updatingCustomerId === customer.customerId
                              }
                            >
                              {updatingCustomerId === customer.customerId
                                ? "Approving"
                                : "Approve"}
                            </button>
                          </>
                        )}
                        {customer.status === "Active" && (
                          <>
                            <button
                              type="button"
                              className="w-32 py-2 font-semibold text-xs text-white bg-[#FF0000] rounded-full"
                              onClick={() =>
                                handleStatusChange(
                                  "inactivate",
                                  customer.customerId
                                )
                              }
                              disabled={
                                updatingCustomerId === customer.customerId
                              }
                            >
                              {updatingCustomerId === customer.customerId
                                ? "Updating"
                                : "Inactive"}
                            </button>
                          </>
                        )}
                        {customer.status === "Inactive" && (
                          <>
                            <button
                              type="button"
                              className="w-32 py-2 font-semibold text-white bg-[#008000] rounded-full"
                              onClick={() =>
                                handleStatusChange(
                                  "activate",
                                  customer.customerId
                                )
                              }
                              disabled={
                                updatingCustomerId === customer.customerId
                              }
                            >
                              {updatingCustomerId === customer.customerId
                                ? "Updating"
                                : "Active"}
                            </button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {dataLoading && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <CircularProgress />
                        <Typography variant="body2">Loading Data...</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  <tr ref={bottomRef}></tr>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </div>

      {showDetailsPopup && (
        <div className="fixed inset-0 flex items-center justify-center pt-10 bg-gray-500 bg-opacity-75">
          <div className="lg:p-6 bg-white rounded-lg lg:w-[600px] lg:h-[450px] w-[90%] max-h-[80%] py-10 px-4 overflow-auto">
            <div className="flex justify-end">
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={closeDetailsPopup}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="flex flex-col w-full h-full px-2">
              <div className="flex justify-center text-xl font-bold">
                All Details
              </div>
              <div className="flex flex-col w-full gap-6 pt-8 mx-4 lg:flex-row">
                <div className="flex flex-col w-full gap-3 lg:w-1/2">
                  <div className="flex flex-col">
                    <div className="text-base font-semibold">First Name</div>
                    <div className="text-sm">{customerData.firstName}</div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-base font-semibold">Last Name</div>
                    <div className="text-sm">{customerData.lastName}</div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-base font-semibold">
                      Contact Number
                    </div>
                    <div className="text-sm">{customerData.contactNumber}</div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-base font-semibold">Address</div>
                    <div className="text-sm">{customerData.address}</div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-base font-semibold">State</div>
                    <div className="text-sm">{customerData.state}</div>
                  </div>
                </div>

                <div className="flex flex-col w-full gap-3 lg:w-1/2">
                  <div className="flex flex-col">
                    <div className="text-base font-semibold">Email Address</div>
                    <div className="text-sm">{customerData.emailAddress}</div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-base font-semibold">City</div>
                    <div className="text-sm">{customerData.city}</div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-base font-semibold">Postal Code</div>
                    <div className="text-sm">{customerData.postalCode}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
